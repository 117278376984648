<template>
	<b-nav-item @click="skin = isDark ? 'light' : 'dark'">
		<p class="m-0">
			<feather-icon :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" :style="'color:' + (isDark ? '#d0d2d6' : '#5e5873')" size="21" />
		</p>
	</b-nav-item>
</template>

<script>
	import useAppConfig from "@core/app-config/useAppConfig";
	import { computed } from "@vue/composition-api";
	import { BNavItem } from "bootstrap-vue";

	export default {
		components: {
			BNavItem,
		},
		setup() {
			const { skin } = useAppConfig();

			const isDark = computed(() => skin.value === "dark");

			return {
				skin,
				isDark,
			};
		},
	};
</script>
